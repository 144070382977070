//const bugsnag = require('bugsnag-js')
//global.bugsnag = bugsnag(BUGSNAG_APIKEY)

// jQuery (defined as a global since so many plugins look for it in the global scope)
global.jQuery = require('jquery')

// rkt utils JS library
require('./utils')

// stop back button popping the scroll position
require('scroll-restoration-polyfill')
history.scrollRestoration = 'manual'

// main js at last
require('./main')