import $ from 'jquery'
import 'gsap'

const logo = $('#navLogo')

module.exports = {
	namespace: 'home',
	beforeEnter() {
		logo.addClass('lg')

		let $welcomeScroll = $('a#welcomeScroll')
		$welcomeScroll.on('click', function () {
			let target = $($welcomeScroll.data('scrollto'))
			let offset = target.offset()
			window.scrollTo({
				top: offset.top,
				left: 0,
				behavior: 'smooth'
			})
		})
		
	},
	afterEnter(){
		let scroll = $(window).scrollTop();

		$(window).scroll(function () {
			scroll = $(window).scrollTop();
			if (scroll >= 50) {
				logo.removeClass('lg')
			} else {
				logo.addClass('lg')
			}
		});
	},
	beforeLeave(){
		$(window).off()
	},
	afterLeave() {
		logo.removeClass('lg')
	}
}
