import barba from '@barba/core'
const preloader = require('../utils/preload')
const lazyLoad = require('../utils/lazyLoad')

// import all transitions in the barba transitions folder
const transitions = []
const t = require.context('./transitions/', false, /\.js$/)
t.keys().forEach(key => transitions.push(t(key)))

// import all views in the barba views folder
const views = []
const v = require.context('./views/', false, /\.js$/)
v.keys().forEach(key => views.push(v(key)))

// global barba hooks for preload/lazyload and other stuff if needed
var initialPreload = true
barba.hooks.beforeEnter(({next}) => {
	if(initialPreload){
		initialPreload = false
		preloader.start()
		return preloader.load({element: document.body })
	}

	const themestyle = next.container.dataset.theme
	const is_page_home = next.container.dataset.ishome
	document.body.dataset.theme = themestyle || 'light'
	document.body.dataset.ishome = is_page_home || 'false'
})
barba.hooks.afterEnter(({next}) => {
	lazyLoad(next.container)
})

barba.init({
	transitions,
	views,
	timeout: 5000
})