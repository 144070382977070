import $ from 'jquery'
import 'gsap'

const mapJson = require('./map-style.json')

module.exports = {
	namespace: 'locations',
	beforeEnter() {

		let $welcomeScroll = $('a#welcomeScroll')
		$welcomeScroll.on('click', function () {
			let target = $($welcomeScroll.data('scrollto'))
			let offset = target.offset()
			window.scrollTo({
				top: offset.top,
				left: 0,
				behavior: 'smooth'
			})
		})

	},
	afterEnter(){

		function initMap() {
			const adelaide = { lat: -34.928497, lng: 138.600739 };

			if(typeof LOCATIONS !== "undefined") {

				let loc_maps = {}
				let loc_markers = {}

				//loop through locations and create a map object:
				for (let i = 0; i < LOCATIONS.length; i++) {

					let lat_lng = {
						lat: Number(LOCATIONS[i].latitude),
						lng: Number(LOCATIONS[i].longitude),
					}

					loc_maps.i = new google.maps.Map(
						document.getElementById('location-map' + (i + 1)), {
						zoom: Number(LOCATIONS[i].map_zoom),
						center: lat_lng,
						styles: mapJson,
						disableDefaultUI: true,
						scrollwheel: false,
						navigationControl: false,
						mapTypeControl: false,
						scaleControl: false
					})

					loc_markers[i] = new google.maps.Marker({
						position: lat_lng,
						map: loc_maps.i,
						title: LOCATIONS[i].title
					});

				}
			} else {
				console.log("LOCATIONS are undefined")
			}
		}
		initMap()

	},
	beforeLeave(){

	},
	afterLeave() {

	}
}