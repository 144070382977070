import $ from 'jquery'
import { TweenLite } from 'gsap'
const preloader = require('../../utils/preload')

module.exports = {

	beforeEnter({next}){
		// hide the new container
		TweenLite.set(next.container, { opacity: 0})

		// return a promise that will resolve once images are preloaded for this page
		return preloader.load({element:next.container})
	},

	enter({next}) {
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		// do the tween, calling done() onComplete
		TweenLite.to(next.container, .5, { opacity: 1, onComplete:() => done() })
	},

	afterEnter({next}){
		// do any cleanup for the enter animation if needed
	},


	beforeLeave({current}){
		// initialize leave animation
	},

	leave({current}) {
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		// do the tween, calling done() onComplete
		TweenLite.to(current.container, .5, { opacity: 0, onComplete:() => done() })
	},

	afterLeave({current}){
		// clean up after leave animation
		const $old = $(current.container)
		$old.css({top: "-"+window.scrollY+"px"})
		$(window).scrollTop(0)
		$old.hide()

		// show the preloader
		preloader.start()
	}
}
