const rkt = {
	setNavActive: require('./setNavActive'),
	navController: require('./navController'),
	preload: require('./preload'),
	lazyLoad: require('./lazyLoad')
}

// preloader
function onPreloadComplete(){
	rkt.setNavActive.update()
}
rkt.preload.rkt(onPreloadComplete)


// global vars/exports
global.rkt = module.exports = rkt
