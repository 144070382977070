import $ from 'jquery'
import 'gsap'

module.exports = {
	namespace: 'product_category',
	beforeEnter() {

	},
	afterEnter(){

	},
	beforeLeave(){

	},
	afterLeave() {

	}
}
