import $ from 'jquery'
const navController = require('./utils/navController')
const setNavActive = require('./utils/setNavActive').init
const $document = $(document)

$document.ready(() => {

	// preloader setup
	require('./preload')

	// kicks off the barba stuff
	require('./barba')

	navController({
		trigger: "#mLogo > .mnav-but",
		setActive: "ul.nav",
		autoClose: "ul.nav a",
		breakPoint: 1440
	})

	setNavActive({
		"nav li": {
			a: "> a",
			fuzzy: false, // if you want fuzzy matching set to true
			parents: { top: "nav", element:"li" } // if your nav doesnt have nested items/sub navs, this isnt needed
		}
	})

	const $forms = $(".subscribe-form")

	if ($forms.length > 0) {
		// const $parsley = $forms.parsley()

		$forms.on("submit", (event) => {
			// console.log('Submitting...')
			// console.log(event.target)
			const $form = $(event.target),
				$submit = $form.find('button')

			event.preventDefault()
			var data = $form.serialize(),
				url = "/!/mail/subscribe",
				name = $form.find('input.first_name').val()

			$form.removeClass("success").removeClass("error")
			$form.addClass("submitting")
			$submit.text('Please wait...')
			$.ajax(url, {
				data: data,
				type: "POST",
				success: function (data) {
					// console.log('Successfully subscribed.')
					$form.find(".form-row").hide()
					$("<p>Thanks " + name + "! You're in!</p>").appendTo($form)
					$form.removeClass("submitting").removeClass("error")
					$form.addClass("success")
					$submit.val('Thanks!').prop('disabled', true)
					$form.find('.thankyou').show()
				},
				error: function (data) {
					// bugsnagClient.notify('Subscribing on Contact Form failed.')
					console.log(data)
					$form.removeClass("submitting").removeClass("success")
					$form.addClass("error")
					$submit.html('Error.')
				}
			})
		})
	}

})
