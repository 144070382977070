import $ from 'jquery'

const mapJson = require('./map-style.json')

module.exports = {
	namespace: 'contact',
	beforeEnter() {

	},
	afterEnter() {
		function initMap() {
			if (typeof CONTACTMAP !== "undefined") {
				let lat_lng = {
					lat: Number(CONTACTMAP.lat),
					lng: Number(CONTACTMAP.lng),
				}
				let map = new google.maps.Map(
					document.getElementById('contactMap'), {
					zoom: Number(CONTACTMAP.map_zoom),
					center: lat_lng,
					styles: mapJson,
					disableDefaultUI: true,
					scrollwheel: false,
					navigationControl: false,
					mapTypeControl: false,
					scaleControl: false
				})
				let marker = new google.maps.Marker({
					position: lat_lng,
					map: map,
					title: CONTACTMAP.title
				});
			}
		}
		initMap()
	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
