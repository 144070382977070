import $ from 'jquery'
const preload = require('./utils/preload')
const ProgressBar = require('progressbar.js')

const progressBarLine = new ProgressBar.Line('#preload-bar', {easing: 'easeInOut'})
const $progressBar = $("#preload-bar")
const $progressCounter = $('#preload-counter span')
var $overlay = $("#preload-overlay")

preload.init({
	start: function(){
		progressBarLine.set(0)
		TweenMax.from($progressBar, 0.4, {autoAlpha:0, delay:0, ease:Circ.easeInOut, force3D:true})
		$progressCounter.text('0%')
	},
	progress: function(event){
		progressBarLine.set(event.progress)
		$progressCounter.text(((event.progress)*100).toFixed() + '%')
	},
	complete: function(){
		// progressBarLine.set(1)
		TweenMax.to($progressBar, 0.4, {autoAlpha:0, delay:0.5, ease:Circ.easeInOut, force3D:true}, hideOverlay())
		TweenMax.to($progressCounter, 0.4, {autoAlpha:0, delay:0.5, ease:Circ.easeInOut, force3D:true}, hideOverlay())
		function hideOverlay() {
			if($overlay){
				TweenMax.to($overlay, 0.4, {autoAlpha:0, delay:0.5, ease:Circ.easeInOut, force3D:true}, preloadComplete())
				function preloadComplete() {
					$overlay = false
				}
			}
		}
	}
})
