import $ from 'jquery'
import 'gsap'

module.exports = {
	namespace: 'wholesale',
	beforeEnter() {

		let $welcomeScroll = $('a#welcomeScroll')
		$welcomeScroll.on('click', function(){
			let target = $($welcomeScroll.data('scrollto'))
			let offset = target.offset()
			window.scrollTo({
				top: offset.top, 
				left: 0,
				behavior: 'smooth'
			})
		})

	},
	afterEnter(){
		
	},
	beforeLeave(){
		
	},
	afterLeave() {
		
	}
}
