import $ from 'jquery'

const SRC = "src"
const PRELOAD_SRC = "data-preload-src"
const PRELOAD_BG = "data-preload-bg"

var queue, mainOptions, rktFunc, initialPreloadDone = false, enabled = true
var promise, promiseReject, promiseResolve, completeTimeout

// init from main js file
function init(options){
	mainOptions = options
}

// rkt callback for load complete
function rkt(func){
	rktFunc = func
}

// start a new preload
function start(){
	if(!enabled) return
	if(promiseReject) promiseReject()
	if(mainOptions && mainOptions.start) mainOptions.start()
}

// main load function
function load(options){
	if(!enabled) return
	initialPreloadDone = true

	if(queue){
		queue.reset()
		queue.removeAll()
	}

	var $element = options && options.element ? $(options.element) : $("body"),
		preloadItems = [], preloadItemsLength, i, item, $item, src,
	
	queue = new createjs.LoadQueue()
	queue.setMaxConnections(5)

	// loop through all items and look for img and bg image data attributes
	$element.find("["+PRELOAD_SRC+"], ["+PRELOAD_BG+"]").each(function(){
		src = this.getAttribute(PRELOAD_SRC) ? this.getAttribute(PRELOAD_SRC) : this.getAttribute(PRELOAD_BG)
		if(!src) return
		src = src.trim()
		if(src.length < 2) return
		queue.loadFile(src, false)
		preloadItems.push(this)
	})

	preloadItemsLength = preloadItems.length
	if(preloadItemsLength < 1) {
		complete(options)
		return Promise.resolve()
	}

	promise = new Promise((resolve, reject) => {
		promiseResolve = resolve
		promiseReject = reject

		queue.on("progress", function(event){
			if(mainOptions && mainOptions.progress) mainOptions.progress(event)
			if(options && options.progress) options.progress(event)
		})

		queue.on("complete", function(){
			// loop through all the items and assign the actual src/style properties from the data attrs
			for(i = 0; i<preloadItemsLength; i++){
				item = preloadItems[i]
				if(item.hasAttribute(PRELOAD_SRC))
					item.setAttribute(SRC, item.getAttribute(PRELOAD_SRC))
				else if(item.hasAttribute(PRELOAD_BG))
					$(item).css("background-image", 'url("'+item.getAttribute(PRELOAD_BG)+'")' )
			}

			// wait a little for the images to be loaded from cache then complete
			if(completeTimeout) clearTimeout(completeTimeout)
			completeTimeout = setTimeout(complete, 50)
		})

		queue.on("error", function(){
			reject()
		})

		queue.load()
	})

	return promise
}

function complete(options){
	if(mainOptions && mainOptions.complete) mainOptions.complete()
	if(options && options.complete) options.complete()
	if(rktFunc) rktFunc()
	promiseReject = false
	if(promiseResolve) promiseResolve()
}

function getPromise(){
	if(promise) return promise
	return Promise.resolve()
}

module.exports = {
	init,
	rkt,
	start,
	load,
	getPromise,
	initialPreloadDone: () => initialPreloadDone,
	enable: () => enabled = true,
	disable: () => enabled = false
}
