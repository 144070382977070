import $ from 'jquery'

const SRC = "src"
const LAZY_LOAD_SRC = "data-lazy-load-src"
const LAZY_LOAD_BG = "data-lazy-load-bg"
const LAZY_LOADING = "lazy-loading"

var loader, promise, promiseReject

// main load function
module.exports = function(element){
	// is there an old promise to reject
	if(promiseReject) promiseReject()

	if(loader){
		loader.reset()
		loader.removeAll()
	}

	var $element = element ? $(element) : $("body"), src, needed = false
	
	loader = new createjs.LoadQueue()
	loader.setMaxConnections(5)

	// loop through all items and look for img and bg image data attributes
	$element.find("["+LAZY_LOAD_SRC+"], ["+LAZY_LOAD_BG+"]").each(function(){
		src = this.getAttribute(LAZY_LOAD_SRC) ? this.getAttribute(LAZY_LOAD_SRC) : this.getAttribute(LAZY_LOAD_BG)
		if(!src) return
		src = src.trim()
		if(src.length < 2) return
		loader.loadFile({src, data:{element:this}}, false)
		$(this).addClass(LAZY_LOADING)
		needed = true
	})

	// if nothing needs to be lazyloaded return resolved promise
	if(!needed) return Promise.resolve()

	promise = new Promise((resolve, reject) => {
		promiseReject = reject

		loader.on("fileload", function(event){
			var item = event.item.data.element,
				$item = $(item).removeClass(LAZY_LOADING)

			if(item.hasAttribute(LAZY_LOAD_SRC))
				item.setAttribute(SRC, item.getAttribute(LAZY_LOAD_SRC))
			else if(item.hasAttribute(LAZY_LOAD_BG))
				$item.css("background-image", 'url("'+item.getAttribute(LAZY_LOAD_BG)+'")' )
		})

		loader.on("complete", function(){
			promiseReject = false
			resolve()
		})

		loader.load()
	})

	return promise
}
