import $, {
	extend,
	each
} from "jquery"

module.exports = function (options) {

	const $window = $(window)
	var windowWidth = $window.width()

	const settings = {
		breakPoint: -1
	}

	var active = false
	const publics = {
		disabled: false,
		toggle,
		open,
		close,
		isOpen,
		isMobile,
		isDesktop
	}

	// merge in options
	extend(true, settings, options)

	settings.$setActive = $(settings.setActive)

	// store texts
	each(settings.text, function (target, value) {
		settings.text[target] = {
			active: value,
			inactive: $(target).text()
		}
	})

	// store htmls
	each(settings.html, function (target, value) {
		settings.html[target] = {
			active: value,
			inactive: $(target).html()
		}
	})

	// store classes
	each(settings.class, function (target, value) {
		let replace = false
		// if its an array, we want to replace instead of merge class
		if (Array.isArray(value)) {
			replace = true
			value = value[0]
		}
		settings.class[target] = {
			replace: replace,
			active: value,
			inactive: $(target).attr('class')
		}
	})

	$(settings.trigger).on("click", toggle)

	$(settings.autoClose).on("click", function () {
		if (active) close()
	})

	function set(state) {
		if (publics.disabled) return
		active = state

		// set texts
		each(settings.text, function (target, value) {
			if (active)
				$(target).text(value.active)
			else
				$(target).text(value.inactive)
		})

		// set htmls
		each(settings.html, function (target, value) {
			if (active)
				$(target).html(value.active)
			else
				$(target).html(value.inactive)
		})

		// set classes
		each(settings.class, function (target, value) {
			if (active)
				target.replace ? $(target).attr('class', value.active) : $(target).addClass(value.active)
			else
				target.replace ? $(target).attr('class', value.inactive) : $(target).removeClass(value.active)
		})

		// set actives
		if (active) {
			settings.$setActive.addClass("active")
			if (settings.onOpen) settings.onOpen()
		} else {
			settings.$setActive.removeClass("active")
			if (settings.onOpen) settings.onClose()
		}
	}

	if (settings.breakPoint > 0) {
		var resizeTimeout
		$window.on('resize', function () {
			clearTimeout(resizeTimeout)
			resizeTimeout = setTimeout(onResize, 50)
		})
	}

	let onMobile = $window.width() <= settings.breakPoint

	function onResize() {
		var newWindowWidth = $window.width()
		if (newWindowWidth >= windowWidth && newWindowWidth > settings.breakPoint) {
			if (!onMobile) return
			if (settings.onDesktop)
				settings.onDesktop()
			else
				close()
			onMobile = false
		} else if (newWindowWidth <= windowWidth && newWindowWidth <= settings.breakPoint) {
			if (onMobile) return
			if (settings.onMobile) settings.onMobile()
			onMobile = true
		}
		windowWidth = newWindowWidth
	}

	function open() {
		set(true)
	}

	function close() {
		set(false)
	}

	function toggle() {
		set(!active)
	}

	function isOpen() {
		return active
	}

	function isDesktop() {
		return !onMobile
	}

	function isMobile() {
		return onMobile
	}

	return publics
}
